import _overwrite from "utilise/overwrite";
import _header from "utilise/header";
import _extend from "utilise/extend";
import _merge from "utilise/merge";
import _attr from "utilise/attr";
import _not from "utilise/not";
import _key from "utilise/key";
import _set from "utilise/set";
import _fn from "utilise/fn";
import _is from "utilise/is";
import _log from "utilise/log";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

function _nullRequire(id) {
  var e = new Error("Cannot find module '" + id + "'");
  e.code = "MODULE_NOT_FOUND";
  throw e;
}

// -------------------------------------------
// Adds support for data resources
// -------------------------------------------
exports = function data(ripple) {
  log("creating");
  ripple.on("change.data").filter(([name, change]) => header("content-type", "application/data")(ripple.resources[name])).filter(([name, change]) => change && change.key).map(([name, change]) => ripple.resources[name].body.emit("change", change || null, not(is.in(["bubble"]))));
  ripple.types["application/data"] = {
    header: "application/data",
    ext: "*.data.js",
    selector: res => `[data~="${res.name}"]`,
    extract: el => (attr(`data`)(el) || "").split(" "),
    check: res => is.obj(res.body),

    load(res) {
      let exported = _nullRequire(res.headers.path);

      exported = exported.default || exported;
      exported = is.fn(exported) ? exported(ripple) : exported;
      res.headers["content-type"] = (this || _global).header;
      ripple(merge(res)(exported));
      return ripple.resources[res.name];
    },

    parse(res) {
      const existing = ripple.resources[res.name] || {};
      extend(res.headers)(existing.headers);
      res.body = set()(res.body || [], existing.body && existing.body.log, is.num(res.headers.log) ? res.headers.log : -1);
      overwrite(res.body.on)(listeners(existing));
      res.body.on("change.bubble", change => {
        ripple.emit("change", ripple.change = [res.name, change], not(is.in(["data"])));
        delete ripple.change;
      });
      if (res.headers.loaded && !res.headers.loading) res.headers.loading = Promise.resolve(res.headers.loaded(ripple, res)).then(() => {
        delete res.headers.loading;
        return res;
      });
      return res;
    }

  };
  return ripple;
};

const overwrite = _overwrite,
      header = _header,
      extend = _extend,
      merge = _merge,
      attr = _attr,
      not = _not,
      key = _key,
      set = _set,
      fn = _fn,
      is = _is,
      log = _log("[ri/types/data]"),
      listeners = key("body.on");

export default exports;